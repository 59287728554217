import type { ImageItem, VideoItem } from './media'
import type { Products } from '#root/api/clients/product/Products'
import type { ProductAttributes, ProductVariants } from '#root/api/clients/product/data-contracts'

export interface Badge {
  icon?: string
  label?: string
}

export interface Eyebrow {
  details?: string
  id?: string
  label?: string
}

export interface Breadcrumb {
  url?: string
  label: string
}

export interface Price {
  current: number
  original: number
}

export interface PriceFull {
  lowCurrent: number
  highCurrent: number
  lowOriginal: number
  highOriginal: number
  currency?: string
  saleDiscountAmount?: number
  historicBestPrice?: number
  percentagePriceDiscount?: number
}

export interface Rating {
  score: number
  reviewsCount: number
  recommendation: string
}

export interface ColorSwatch {
  image: string
  color: string[]
}

export type ColorSwatchShape = 'round' | 'rectangle'

export interface ProductDetailContent {
  label: string
  text?: string
  thumbnail?: string
  url?: string
}

export interface ScaleContent extends ProductDetailContent {
  maxValue: string
  value: string
  minLabel: string
  maxLabel: string
  label: string
}

export const DETAIL_LAYOUTS = [
  'bulletin',
  'detailCard',
  'grid',
  'keyValue',
  'modalGrid',
  'scale'
] as const

export type ProductDetailLayout = typeof DETAIL_LAYOUTS[number]

export interface ProductDetail {
  data: (ProductDetailContent | Partial<ScaleContent>)[]
  expanded?: boolean
  id?: 'benefitsAttribute' | 'benefitRatings' | 'careInstructions' | 'featuresBulletin' | 'featuresKeyValue' | 'productDescription' | 'productDetails' | 'productFeatures'
  label?: string
  layout: ProductDetailLayout
}

export interface BaseOption {
  id?: string
  label: string
  value: string
  available: boolean
}

export interface ColorOption extends BaseOption {
  id: string
  slug: string
  url: string
  swatch?: ColorSwatch
  thumbnail?: string
}

export type ProductAttribute = ProductAttributes[number]

export type ProductAttributeType = ProductAttribute['type']

export type AttributeSelection = Partial<Record<ProductAttributeType, string>>

export interface ProductAttributeSelectorSize {
  [key: string]: 'sm' | 'md'
}

export interface OptionGroups {
  groupValue?: PriceFull
  options: ColorOption[]
}

export interface ProductUpsell {
  id: string
  image: string
  name: string
  price: PriceFull
  sku: string
  url: string
}

export type ProductUpsellSelected = string[]

export interface ProductJsonLdAggregateRating {
  '@type': string
  'ratingValue': number
  'reviewCount': number
}

export interface ProductJsonLdItemTypeName {
  '@type': string
  'name': string
}

export interface ProductJsonLdOffers {
  '@type': string
  'availability': string
  'price': number
  'priceCurrency': string
  'seller': ProductJsonLdItemTypeName
}

export interface ProductJsonLd {
  '@context': string
  '@type': string
  'aggregateRating': ProductJsonLdAggregateRating
  'brand': ProductJsonLdItemTypeName
  'description': string
  'image': string
  'mpn': string
  'name': string
  'offers': ProductJsonLdOffers
}

export interface ProductDetails {
  attributes: ProductAttribute[]
  badge: Badge
  breadcrumbs: Breadcrumb[]
  currency: string
  details: ProductDetail[]
  eyebrow?: Eyebrow
  gallery: (ImageItem | VideoItem)[]
  id: string
  itemId?: string // id of cart or save for later item
  modelMeasurements: string
  name: string
  price: PriceFull
  productJsonLd?: ProductJsonLd
  rating: Rating
  styleId: string
  upsell?: ProductUpsell[]
  url: string
  variants: ProductVariants
  span?: number
  notifyMe?: boolean
}

export interface ProductSet {
  productSetId: string
  text: string
  description: string
  images: string[]
  products?: ProductSetItem[]
}

export interface ProductSetItem {
  id: string
  thumbnail: string
}

export interface SearchData {
  numFound: number
  facets: {
    id: string
    label: string
    type: string
    queryParam: string
  }[]
  result: {
    id: string
    type: string
    name: string
    title: string
    url: string
    date: string
    eyebrow: string
    description: string
    picture: {
      id: string
      type: string
      name: string
      contentType: string
      url: string
      alt: string
      height: number
      width: number
      seoTitle: string
      seoDescription: string
    }
    tags: {
      id: string
      label: string
    }[]
  }[]
}

export type SelectedVariant = {
  id: string
  notifyMe?: boolean
}

export type GiftCardFormFields = {
  fullName: string
  email: string
  message: string
  policy?: boolean
  amount: string
  size: string
}

export type ProductCatalogQuery = Parameters<Products['$catalog']>[0]

export type ProductSearchQuery = Parameters<Products['$search']>[0]
