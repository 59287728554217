// @see https://cloudinary.com/documentation/resizing_and_cropping#control_gravity
import type { Responsive } from '#types/common'
import breakpoints from '#core/design-system/breakpoints.json'

type ImageTransformationsAlignment = 'north_east' | 'north' | 'north_west' | 'east' | 'center' | 'west' | 'south_east' | 'south' | 'south_west'

interface ImageTransformations {
  /**
   * The (compass) direction to align the image to.
   * This is used for images with "white space", where the original size does not match the requested aspect ratio.
   */
  align?: ImageTransformationsAlignment
  /** The format of the image. */
  format?: 'auto' | 'png'
  /** The height of the image, in pixels. */
  height?: number
  /** Sets both the width and height at the same time. */
  size?: number
  /** The width of the image, in pixels. */
  width?: number
}

interface ResponsiveImageTransformations {
  /**
   * The (compass) direction to align the image to.
   * This is used for images with "white space", where the original size does not match the requested aspect ratio.
   */
  align?: ImageTransformationsAlignment
  /** The height of the image, in pixels. */
  height?: Responsive<number>
  /** Sets both the width and height at the same time. */
  size?: Responsive<number>
  /** The width of the image, in pixels. */
  width?: Responsive<number>
}

export const defaultTransformationTokens: string[] = []

export const transformationTokenMap: Record<keyof ImageTransformations, string> = {
  align: 'c_pad,g_',
  format: 'f_',
  height: 'h_',
  size: 'w_',
  width: 'w_'
}

const DEFAULT_NAMED_TRANSFORMATION = 't_img'
const legacyParams = { qlt: 50, resMode: 'sharp2', op_usm: '0.9,1.0,8,0', }

/**
 * Applies the appropriate Scene7 transformations to a given image URL.
 * @param url URL used to construct a new URL object.
 * @param transformations An object containing the transformations to apply to the image.
 * @param transformations.width The transformations width to apply to the image.
 * @param transformations.height The transformations height to apply to the image.
 * @param transformations.size The transformations size to apply to the image.
 * @deprecated This code can be removed after the migration to Cloudinary is complete.
 * @category Utils
 */
const getImageTransformationsLegacy = (url: string, { width, height, size }: ImageTransformations) => {
  const baseUrl = new URL(url)

  Object.entries(legacyParams).forEach(([key, value]) => {
    // Using set() to delete any possible matching values
    baseUrl.searchParams.set(key, `${value}`)
  })

  if (size) width = height = size
  // Applying 2x image size to all DPR levels for increased quality of the image
  // reference for details: https://digital.vfc.com/jira/browse/GLOBAL15-91223
  if (width) baseUrl.searchParams.set('wid', `${width * 2}`)
  if (height) baseUrl.searchParams.set('hei', `${height * 2}`)

  return decodeURIComponent(`${baseUrl}`)
}

/**
 * A helper function for extracting the transformation values from the consumer-provided options.
 * @param t The transformations to apply to the image.
 * @returns {string[]} An array of transformation values (e.g. ['f_auto', 'c_scale', 'w_650']).
 * @category Utils
 */
const generateTransformationValues = (t: ImageTransformations): string[] => {
  const transformationValues = new Set(defaultTransformationTokens)
  const hasRedundantDimensions = t.size && (t.height || t.width)

  /* If we do not receive an override format, will use auto by default */
  t.format ??= 'auto'

  if (hasRedundantDimensions) {
    delete t.height
    delete t.width
    log.debug('[@domains/core/utils/getImageTransformations] Both a size and height/width value was set. Defaulting to size.')
  }

  for (const [key, token] of Object.entries(transformationTokenMap))
    if (t[key]) transformationValues.add(`${token}${t[key]}`)
  return [...transformationValues]
}

/**
 * Applies the appropriate Cloudinary transformations to a given image URL.
 * @param url URL used to construct new URL object.
 * @param transformations The transformations to apply to the image.
 * @example
 * Images will arrive in the following formats:
 * 'https://assets.timberland.eu/images/t_Thumbnail/v1702342099/TB0A27PQ001-HERO/Brooklyn-Hiker-for-Men-in-Black.png'
 *
 * The default named transformation (e.g. t_Thumbnail) will be replaced with t_img, followed by a set of transformations.
 * For example, if the image above was resized to a width of 650px, the URL would be transformed to:
 * 'https://assets.timberland.eu/images/t_img/f_auto,c_scale,w_650/v1702342099/TB0A27PQ001-HERO/Brooklyn-Hiker-for-Men-in-Black.png'
 * @category Utils
 * @see https://cloudinary.com/documentation/transformation_reference
 * @returns {string} The transformed image URL.
 */

export const getImageTransformations = (url: string = '', transformations: ImageTransformations = {}): string => {
  if (!url) {
    log.debug('[@domains/core/utils/getImageTransformations] Please provide a url.')
    return ''
  }

  if (url.includes(DEFAULT_NAMED_TRANSFORMATION)) {
    log.debug('[@domains/core/utils/getImageTransformations] No op. The transformations have already been applied.')
    return url
  }
  try {
    // @deprecated
    // This code can be removed after the migration to Cloudinary is complete.
    if (!url.includes('//assets.')) return getImageTransformationsLegacy(url, transformations)

    const baseUrl = new URL(url)
    const urlPathValues = baseUrl.pathname.split('/')

    // The URL does not match the expected format.
    if (urlPathValues.length !== 6) {
      log.debug('[@domains/core/utils/getImageTransformations] The URL does not match the expected format.')
      return url
    }

    const [_, __, ___, version, id, fileName] = urlPathValues

    // Applying 2x image size to all DPR levels for increased quality of the image
    // reference for details: https://digital.vfc.com/jira/browse/GLOBAL15-91223
    baseUrl.pathname = `images/${DEFAULT_NAMED_TRANSFORMATION}/${[...generateTransformationValues(transformations)].join(',')}/e_sharpen:60/dpr_2.0/${version}/${id}/${fileName}`

    return decodeURIComponent(`${baseUrl}`)
  }
  catch (error: any) {
    log.error(`[@domains/core/utils/getImageTransformations] Unable to transform the provided URL: ${url}.`, error)
    return ''
  }
}

export const getResponsiveImageTransformations = (url: string = '', t: ResponsiveImageTransformations = {}) =>
  Object.keys(breakpoints).reduce((sources, breakpoint) => {
    const width = t.width?.[breakpoint]
    const height = t.height?.[breakpoint]
    const size = t.size?.[breakpoint]

    if ((width && height) || size)
      sources[breakpoint] = getImageTransformations(url, { width, height, size })

    return sources
  }, {} as Responsive<string>)
