import type { ProductFilters } from '#root/api/clients/product/data-contracts'
import type { FilterItem, FiltersMap, PriceFilter, SearchFilter } from '#types/filters'

export default (_filters: MaybeRefOrGetter<ProductFilters>, onChange?: () => void) => {
  const { defaultSort, sorts } = useAppConfig().pages.plp
  const { $gtm, $t } = useNuxtApp()

  const region = useRegion()
  const route = useRoute()
  const router = useRouter()

  const filtersQuery = ref(JSON.parse(route.query.filters?.toString() || '{}'))
  const filters = computed(() => Object.fromEntries(toValue(_filters)
    .map((item) => [item.code, {
      ...item,
      type: item.layout || 'TileExpanded',
      selected: filtersQuery.value[item.code] || []
    }])) as FiltersMap
  )

  const sortOptions = sorts[region].map((sort) => ({ value: sort, label: $t.sorts[sort] }))
  const sortState = ref(route.query.sort || defaultSort)

  const onFiltersChange = () => {
    const filtersEntries = Object.values(filters.value)
      .filter(({ selected }) => selected?.length)
      .map(({ code, selected }) => [code, selected])

    filtersQuery.value = { ...filtersQuery.value, ...Object.fromEntries(filtersEntries) }

    router.push({
      query: {
        ...route.query,
        filters: Object.keys(filtersQuery.value).length ? JSON.stringify(filtersQuery.value) : undefined,
        sort: sortState.value
      }
    })
    onChange?.()
  }

  const isPriceFilter = (filter: FilterItem): filter is PriceFilter => filter.type === 'PriceInput'
  const isSearchFilter = (filter: FilterItem): filter is SearchFilter => filter.type === 'Search'

  const filterBy = (code: string) => {
    const filter = filters.value[code]
    const { selected, facetId } = filter
    const previous = filtersQuery.value[code] || []

    if (isPriceFilter(filter) || isSearchFilter(filter)) {
      $gtm.push('plpPage.onApplyFilter', code, facetId, selected)
    }
    else if (previous.length < selected.length) {
      const id = selected.filter((item) => !previous.includes(item))[0]
      const { label } = filter.options.find((option) => option.id === id)!
      $gtm.push('plpPage.onApplyFilter', filter.label, facetId, label)
    }
    else {
      const id = previous.filter((item: string) => !selected.includes(item))[0]
      const { label } = filter.options.find((option) => option.id === id)!
      $gtm.push('plpPage.onRemoveFilter', filter.label, facetId, label)
      if (!selected.length) delete filtersQuery.value[code]
    }
    onFiltersChange()
  }

  const removeFilter = ({ code, id }) => {
    const filter = filters.value[code]
    const removedId = id || filter.selected

    if (isPriceFilter(filter) || isSearchFilter(filter)) {
      $gtm.push('plpPage.onRemoveFilter', code, filter.facetId, removedId)
    }
    else {
      const { label } = filter.options.find((option) => option.id === id)!
      $gtm.push('plpPage.onRemoveFilter', filter.label, filter.facetId, label)
    }

    filtersQuery.value[code] = filter.type === 'PriceInput'
      ? []
      : filtersQuery.value[code]?.filter((item: string) => item !== id)

    if (!filtersQuery.value[code].length) delete filtersQuery.value[code]
    onFiltersChange()
  }

  const clearFilters = (updateFiltersQuery = true) => {
    const selectedFilters = Object.values(filters.value).filter((item) => item.selected?.length)
    const category = selectedFilters?.length === 1 ? selectedFilters[0].label : 'All'

    $gtm.push('plpPage.onClearFilters', category)
    filtersQuery.value = {}
    if (updateFiltersQuery)
      onFiltersChange()
  }

  const sortBy = () => {
    $gtm.push('plpPage.onSort', sortOptions.find(({ value }) => value === sortState.value)?.label)
    onFiltersChange()
  }

  return {
    clearFilters,
    filterBy,
    filters,
    filtersQuery,
    removeFilter,
    sortBy,
    sortOptions,
    sortState
  }
}
